@media (prefers-reduced-motion: no-preference) {
  .image-animate-wobble {
    animation: image-wobble infinite 5s linear alternate;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .image-animate-spin {
    animation: image-spin infinite 5s linear alternate;
  }
}

@keyframes image-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes image-wobble {
  from {
    transform: rotate(-30deg);
  }

  to {
    transform: rotate(30deg);
  }
}
